<template>
    <div class="usoItem">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-dialog v-model="modalUsoItem" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloUsoItem }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmUsoItem" v-model="validoUsoItem" lazy-validation>
                                <v-text-field v-model="editUsoItem.nombre" :rules="reglaNombreUsoItem"
                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                </v-text-field>
                                <v-text-field v-model="editUsoItem.alias" :rules="reglaAliasUsoItem"
                                    prepend-inner-icon="mdi-format-color-text" label="Alias" outlined clearable
                                    required>
                                </v-text-field>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarUsoItem">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoUsoItem" color="success" class="mr-4"
                                        @click="guardarUsoItem">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEliminarUsoItem" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrareliminarUsoItem">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimUsoItem">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- tabla -->
            <tabla :header="headersUsoItem" :body="usosItems" tituloTabla="USOS ITEMS" :accionAgregar="true"
                :exportar="true" @recargar="listarUsoItem" @agregar="agregarUsoItem" @actualizar="editarUsoItem"
                @eliminar="eliminarUsoItem" />
            <!-- tabla fin -->
        </v-card>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "usoItem",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables usoItem 
            UidUsoItem: null,
            buscarUsoItem: '',
            validoUsoItem: true,
            modalUsoItem: false,
            modalEliminarUsoItem: false,
            headersUsoItem: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre corto',
                    align: 'start',
                    value: 'alias',
                },
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            usosItems: [],
            reglaNombreUsoItem: [
                v => !!v || 'El nombre del uso es obligatorio.'
            ],
            reglaAliasUsoItem: [
                v => !!v || 'El alias del uso es obligatorio.'
            ],
            editedUsoItemIndex: -1,
            editUsoItem: {
                nombre: '',
                alias: ''
            },
            defaultUsoItem: {
                nombre: '',
                alias: ''
            },
            // fin variables usoItem
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal usoItem 
            tituloUsoItem() {
                return this.editedUsoItemIndex === -1 ? 'Nuevo Uso' : 'Editar Uso'
            },
        },

        watch: {
            // modal usoItem
            modalUsoItem(val) {
                val || this.cancelarUsoItem()
            },
            modalEliminarUsoItem(val) {
                val || this.cerrareliminarUsoItem()
            },
            // fin modal usoItem
        },

        created() {
            // listar usosItems
            this.listarUsoItem()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarUsoItem() {
                try {
                    await fetch(`${this.url_api}/uso_item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.usosItems = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            agregarUsoItem() {
                this.modalUsoItem = true
            },

            editarUsoItem(uso) {
                this.editedUsoItemIndex = this.usosItems.indexOf(uso)
                this.editUsoItem = Object.assign({}, uso)
                this.modalUsoItem = true
                this.UidUsoItem = uso.Uid;
            },

            eliminarUsoItem(uso) {
                this.editedUsoItemIndex = this.usosItems.indexOf(uso)
                this.editUsoItem = Object.assign({}, uso)
                this.modalEliminarUsoItem = true
                this.UidUsoItem = uso.Uid;
            },

            async confirmarElimUsoItem() {
                try {
                    await fetch(`${this.url_api}/uso_item/${this.UidUsoItem}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.usosItems.splice(this.editedUsoItemIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Rerror al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
                this.cerrareliminarUsoItem();
            },

            cancelarUsoItem() {
                this.$refs.frmUsoItem.reset()
                this.$refs.frmUsoItem.resetValidation()
                this.modalUsoItem = false
                this.$nextTick(() => {
                    this.editUsoItem = Object.assign({}, this.defaultUsoItem)
                    this.editedUsoItemIndex = -1
                })
            },

            cerrareliminarUsoItem() {
                this.modalEliminarUsoItem = false
                this.$nextTick(() => {
                    this.editUsoItem = Object.assign({}, this.defaultUsoItem)
                    this.editedUsoItemIndex = -1
                })
            },

            async guardarUsoItem() {
                if (this.$refs.frmUsoItem.validate()) {
                    if (this.editedUsoItemIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/uso_item/${this.UidUsoItem}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editUsoItem),
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.usosItems[this.editedUsoItemIndex], this
                                            .editUsoItem);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error)
                        }
                        this.UidUsoItem = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/uso_item`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editUsoItem),
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarUsoItem();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error)
                        }
                    }
                    this.cancelarUsoItem()
                }
            },
            // fin CRUD usosItems
        },
    }
</script>